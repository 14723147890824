import { create } from 'zustand';

export enum Theme {
  Light = 'light',
  Dark = 'dark'
}

type ThemeStore = {
  theme: Theme;
  setTheme: ( theme: Theme ) => void;
}

export const useThemeStore = create<ThemeStore>( ( set ) => ( {
  theme: Theme.Dark,
  setTheme: ( theme: Theme ) => {
    set( {
      theme
    } );
  }
} ) );
